
import {defineComponent} from 'vue';
import GameVolumeIntro from "@/components/game-volume/game-volume-intro.vue"; // @ is an alias to /src
import GamePalitraIntro from "@/components/game-palitra/game-palitra-intro.vue";

export default defineComponent({
    name: 'GameIntroWithID',
    inheritAttrs: false,
    components: {
        GameVolumeIntro,
        GamePalitraIntro
    }
});
