
import {defineComponent, inject, onMounted, Ref, ref} from "vue";
import {useRoute, useRouter} from "vue-router";
import {exitFullScreen, fullScreenWithoutStatus} from "@/source/functions/fullScreen";
import {TGamePlayedPayload} from "@/api/modules/game/types";
import checkErrorCode from "@/source/functions/responseHandler";
import store from "@/store";
import {TApi} from "@/api/types";
import {useToast} from "primevue/usetoast";

export default defineComponent({
    name: "game-palitra-intro",

    setup() {
        onMounted(() => {
            exitFullScreen();
            if (route.query.isEnd) {
                isEnd.value = true;
                point.value = route.query?.point ? Number(route.query?.point) : 0
            }
            if (isEnd.value) {
                saveProgress();
            }
        });

        // const volumeStart: Ref<boolean> = inject('volumeStart') as Ref<boolean>;
        const api: TApi = inject('api') as TApi;
        const checkToken: any = inject('checkToken');
        const router = useRouter();
        const route = useRoute();
        const toast = useToast();
        const isEnd = ref(false);
        const html = document.documentElement;
        const point: Ref<null | number> = ref(null);
        const startGame = () => {
            fullScreenWithoutStatus(html);
            router.push({ name: 'GamesWithID', params: { game_id: 'palitra' } });
        };

        const palitra_id = async () => {
	        return await store.getters['games/getGamesClassifierCode']?.palitra?.id ?? ''
        };

        const user_id = () => {
            return store.getters['users/getCurrentUser']?.id;
        }

        const goBack = () => {
            router.push({ name: 'Games'});
        };

        const saveProgress = async () => {
            let id = await palitra_id();
            let userId = await user_id();
            const payload = {user_id: userId, game_id: id, points: Number(point.value)};
            if (route.query.isEnd === 'break') {
                router.replace({ name: 'GamesIntroWithID', params: { game_id: 'palitra'}});
                return new Promise((resolve) => {resolve(true);});
            } else {
                checkToken().then(() => {
                    api.apiGame.setGamePlayed(payload as TGamePlayedPayload).then(() => {
                        toast.add({
                            severity: 'success',
                            summary: 'Данные сохранены!',
                            life: 3000
                        });
                        router.replace({ name: 'GamesIntroWithID', params: { game_id: 'palitra'}});
                    }).catch((error: any) => {
                        console.log(error)
                        toast.add({severity: 'error', summary: 'Ошибка!', detail: `Не удалось сохранить данные`, life: 3000});
                        toast.add(checkErrorCode(error.response.status));
                    });
                });
            }
        }

        return {
            startGame,
            goBack,
            palitra_id,
            isEnd,
            point,
            user_id
        }
    }
});
