import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_game_volume_intro = _resolveComponent("game-volume-intro")!
  const _component_game_palitra_intro = _resolveComponent("game-palitra-intro")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.$route.params?.game_id === 'volume')
      ? (_openBlock(), _createBlock(_component_game_volume_intro, { key: 0 }))
      : _createCommentVNode("", true),
    (_ctx.$route.params?.game_id === 'palitra')
      ? (_openBlock(), _createBlock(_component_game_palitra_intro, { key: 1 }))
      : _createCommentVNode("", true)
  ], 64))
}